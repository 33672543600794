import React, { useEffect, useRef, useState } from 'react';
import { Skeleton } from '@campus/components';
import { calculateEmptyItemCount, calculateItemsPerLine } from '../../../utils';
import DigitalObjectItem from './components/DigitalObjectItem';
import { MaterialList, Chapters, DisabledItem } from './styles';
const itemWidth = 133;
const itemHeight = 178;
const columnGap = 20;
export const DigitalObjectList = ({ className, items, loadingItems, numberOfColumns }) => {
    const materialListRef = useRef(null);
    const chapterListRef = useRef(null);
    const [emptyItemCount, setEmptyItemCount] = useState(0);
    const [itemsPerLine, setItemsPerLine] = useState(0);
    const [selectedObjectId, setSelectedObjectId] = useState(null);
    useEffect(() => {
        setSelectedObjectId((objectId) => {
            return objectId && items.some((o) => o.id === objectId) ? objectId : null;
        });
    }, [items]);
    useEffect(() => {
        if (!materialListRef.current)
            return;
        if (loadingItems) {
            setItemsPerLine(calculateItemsPerLine({
                wrapper: materialListRef.current,
                itemWidth,
                columnGap
            }));
            return;
        }
        function recalculateEmptyItemCount() {
            setEmptyItemCount(calculateEmptyItemCount({
                wrapper: materialListRef.current,
                itemCount: items.length,
                itemWidth,
                columnGap
            }));
        }
        recalculateEmptyItemCount();
        const observer = new ResizeObserver(recalculateEmptyItemCount);
        observer.observe(materialListRef.current, {
            box: 'border-box'
        });
        return () => observer.disconnect();
    }, [items.length, loadingItems]);
    useEffect(() => {
        function handler(event) {
            if (!(event.target instanceof Element)) {
                setSelectedObjectId(null);
                return;
            }
            const objectElement = event.target.closest('[data-object-id]');
            if (objectElement) {
                setSelectedObjectId(objectElement.dataset['objectId']);
                return;
            }
            if (!chapterListRef.current?.contains(event.target)) {
                setSelectedObjectId(null);
            }
        }
        document.addEventListener('click', handler);
        return () => document.removeEventListener('click', handler);
    }, []);
    useEffect(() => {
        function handler(event) {
            if (selectedObjectId && event.key === 'Escape') {
                setSelectedObjectId(null);
            }
        }
        document.addEventListener('keyup', handler);
        return () => document.removeEventListener('keyup', handler);
    }, [selectedObjectId]);
    return (React.createElement(MaterialList, { ref: materialListRef, className: className, "$isAnyObjectSelected": !!selectedObjectId }, loadingItems ? (Array.from({
        length: itemsPerLine
    }).map((_, i) => {
        return (React.createElement(Skeleton, { key: i, width: itemWidth + 'px', height: itemHeight + 'px' }));
    })) : (React.createElement(React.Fragment, null,
        items.map((i) => {
            return (React.createElement(React.Fragment, null,
                React.createElement(DigitalObjectItem, { key: i.id, item: i, "data-object-id": i.id, className: 'object' + (i.id === selectedObjectId ? ' selected' : '') }),
                selectedObjectId === i.id ? (React.createElement(Chapters, { ref: chapterListRef, libraryObject: i, getTriggerElement: () => {
                        return materialListRef.current.querySelector(`[data-object-id='${i.id}']`);
                    }, numberOfColumns: numberOfColumns })) : null));
        }),
        Array.from({
            length: emptyItemCount
        }).map((_, i) => {
            return (React.createElement(DisabledItem, { key: i, "$width": itemWidth, "$height": itemHeight }));
        })))));
};
