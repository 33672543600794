import Segment from './Segment';
class Grade {
    id;
    name;
    segment;
    static fromJson(data) {
        const grade = new Grade();
        grade.id = data.Id;
        grade.name = data.Name;
        grade.segment = data.Segment && Segment.fromJson(data.Segment);
        return grade;
    }
}
export default Grade;
