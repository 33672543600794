import styled from 'styled-components';
export const Item = styled.button `
  width: 133px;
  height: 178px;
  flex-shrink: 0;

  padding: 0;

  text-align: start;

  border: none;
  border-radius: 4px;

  box-shadow: -4.036px 8.072px 11.301px 0px rgba(0, 0, 0, 0.42);

  &:focus-visible {
    outline-offset: 3px;
  }

  ${(props) => props.$backgroundImage
    ? `
        background-image: url("${props.$backgroundImage}");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      `
    : 'background: none;'}
`;
export const ItemInfo = styled.div `
  display: flex;
  flex-direction: column;

  height: 80%;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  padding: 11px 13px 9px 13px;

  background-color: ${(props) => props.$backgroundColor};
`;
export const ItemInfoGrade = styled.div `
  opacity: 0.8;

  font-size: 14px;
  font-weight: 800;
  line-height: 18px;

  color: ${(props) => props.theme.colors.white};
`;
export const ItemInfoSegment = styled.div `
  opacity: 0.4;

  font-size: 9px;
  font-weight: 800;
  line-height: 100%;

  color: ${(props) => props.theme.colors.white};
`;
export const ItemInfoDiscipline = styled.div `
  height: 100%;
  display: flex;
  align-items: flex-end;

  font-size: 12px;
  font-weight: 600;
  line-height: 100%;

  color: ${(props) => props.theme.colors.white};
`;
export const ItemLogo = styled.div `
  display: flex;
  height: 20%;
  width: 100%;

  align-items: center;
  padding-inline: 13px;
  border-radius: 0px 0px 4px 4px;
`;
export const CampusLogo = styled.img `
  height: 15px;
`;
