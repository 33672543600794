import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const PDFBookIcon = styled(CampusIcon).attrs({
    name: 'openBook'
}) `
  display: block;
`;
export const HTMLBookIcon = styled(CampusIcon).attrs({
    name: 'paperWithHtmlTags'
}) `
  display: block;
`;
export const TemplateIcon = styled(CampusIcon).attrs({
    name: 'paperWithCheck'
}) `
  display: block;
`;
export const VideoIcon = styled(CampusIcon).attrs({
    name: 'youtubeLikeIcon'
}) `
  display: block;
`;
