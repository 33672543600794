import React from 'react';
import { LibraryObjectContentType } from '../../../models';
import { HTMLBookIcon, PDFBookIcon, TemplateIcon, VideoIcon } from './styles';
export const ChapterIcon = ({ className, contentType }) => {
    switch (contentType) {
        case LibraryObjectContentType.EMBEDDED:
            return React.createElement(HTMLBookIcon, { className: className });
        case LibraryObjectContentType.PDF:
            return React.createElement(PDFBookIcon, { className: className });
        case LibraryObjectContentType.VIDEO:
            return React.createElement(VideoIcon, { className: className });
        case LibraryObjectContentType.TEMPLATE:
            return React.createElement(TemplateIcon, { className: className });
        default:
            return null;
    }
};
