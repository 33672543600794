class LibraryObject {
    id;
    name;
    contentUrl;
    imageUrl;
    contentType;
    chapters;
    grade;
    discipline;
}
export default LibraryObject;
