import styled from 'styled-components';
import { ChapterSelector } from '../ChapterSelector';
export const MaterialList = styled.div.attrs((props) => ({
    className: (props.className ?? '') +
        (props.$isAnyObjectSelected ? ' with-selection' : '')
})) `
  display: grid;
  gap: 26px 20px;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, 133px);

  .object {
    transition: translate 150ms;
  }

  &.with-selection .chapters {
    margin-top: calc((26px - 11px) * -1);
  }

  &.with-selection .object.selected {
    translate: 0 -14.59px;
  }

  &.with-selection .object:not(.selected) {
    filter: blur(3px);
  }
`;
export const Chapters = styled(ChapterSelector).attrs((props) => ({
    className: (props.className ?? '') + ' chapters'
})) `
  grid-column: 1 / -1;

  min-height: 247.172px;
`;
export const DisabledItem = styled.div `
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;

  position: relative;

  opacity: 0.5;

  border-radius: 4px;

  background: #dbdad1;

  box-shadow: 0px 24px 54px -20px rgba(0, 0, 0, 0.3) inset;

  &::before {
    content: '';

    display: block;

    opacity: 0.1;

    background: #fff;

    position: absolute;
    inset: 0;

    border-radius: inherit;
    border: 1px solid #282934;
  }
`;
