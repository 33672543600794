import React from 'react';
import { LibraryObjectContentType, contentTypeFromEnumValue } from '../../../../../models';
import { Filter, HTMLBookIcon, PDFBookIcon, TemplateIcon, VideoIcon, Wrapper } from './styles';
export const Filters = ({ value, onChange }) => {
    function onClick(event) {
        if (!(event.target instanceof HTMLElement))
            return;
        const button = event.target.closest('[data-content-type]');
        if (!button)
            return;
        const { contentType: enumValue } = button.dataset;
        const contentType = contentTypeFromEnumValue(enumValue);
        onChange(contentType);
    }
    return (React.createElement(Wrapper, { onClick: onClick },
        React.createElement(Filter, { "data-content-type": LibraryObjectContentType.EMBEDDED, "$isActive": value === LibraryObjectContentType.EMBEDDED },
            React.createElement(HTMLBookIcon, null),
            React.createElement("span", null, "Livro HTML")),
        React.createElement(Filter, { "data-content-type": LibraryObjectContentType.PDF, "$isActive": value === LibraryObjectContentType.PDF },
            React.createElement(PDFBookIcon, null),
            React.createElement("span", null, "Livro PDF")),
        React.createElement(Filter, { "data-content-type": LibraryObjectContentType.VIDEO, "$isActive": value === LibraryObjectContentType.VIDEO },
            React.createElement(VideoIcon, null),
            React.createElement("span", null, "V\u00EDdeo")),
        React.createElement(Filter, { "data-content-type": LibraryObjectContentType.TEMPLATE, "$isActive": value === LibraryObjectContentType.TEMPLATE },
            React.createElement(TemplateIcon, null),
            React.createElement("span", null, "Resolu\u00E7\u00E3o de Exerc\u00EDcios"))));
};
