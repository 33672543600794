import React from 'react';
import { attributes, classNames } from '../../constants';
import { Wrapper, Button, Name, Numberr, Icon } from './styles';
export const Chapter = ({ chapter, chapterIndex, columnIndex, isLoading, numberWidth, nonClickable, lastOneInColumn }) => {
    return (React.createElement(Wrapper, { ...{
            [attributes.chapterId]: chapter.id,
            [attributes.columnIndex]: columnIndex
        } },
        React.createElement(Button, { disabled: nonClickable, tabIndex: chapterIndex + 1, "$loading": isLoading, "$borderless": lastOneInColumn, "$firstGridColumnWidth": numberWidth },
            React.createElement(Numberr, { className: classNames.chapterNumber },
                chapterIndex + 1,
                "."),
            React.createElement(Icon, { contentType: chapter.contentType }),
            React.createElement(Name, null, chapter.name))));
};
