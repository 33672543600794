import React from 'react';
import { Item, ItemInfo, ItemInfoDiscipline, ItemInfoSegment, ItemLogo, ItemInfoGrade, CampusLogo } from './styles';
const DigitalObjectItem = ({ item, ...props }) => {
    return item.imageUrl ? (React.createElement(Item, { "$backgroundImage": item.imageUrl, ...props })) : (React.createElement(Item, { ...props },
        React.createElement(ItemInfo, { "$backgroundColor": item.discipline.color },
            React.createElement(ItemInfoGrade, null, item.grade?.name),
            React.createElement(ItemInfoSegment, null, item.grade?.segment?.name),
            React.createElement(ItemInfoDiscipline, null, item.discipline?.name)),
        React.createElement(ItemLogo, null,
            React.createElement(CampusLogo, { src: "https://storagecampusv2.blob.core.windows.net/brand/brandAndCampus.png", alt: "Logo do Campus" }))));
};
export default DigitalObjectItem;
