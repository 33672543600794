export function calculateItemsPerLine(params) {
    params.columnGap ??= 0;
    const width = params.wrapper.offsetWidth;
    let itemCountPerLine = Math.floor(width / (params.itemWidth + params.columnGap));
    const horizontalRemainingSpace = width - itemCountPerLine * (params.itemWidth + params.columnGap);
    if (horizontalRemainingSpace >= params.itemWidth) {
        itemCountPerLine += 1;
    }
    return itemCountPerLine;
}
export function calculateEmptyItemCount(params) {
    const itemCountPerLine = calculateItemsPerLine(params);
    const numberOfItemsInTheLastLine = params.itemCount % itemCountPerLine;
    return numberOfItemsInTheLastLine === 0
        ? 0
        : itemCountPerLine - numberOfItemsInTheLastLine;
}
