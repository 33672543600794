import styled from 'styled-components';
import { ChapterIcon } from '../../../ChapterIcon';
export const Wrapper = styled.li `
  display: grid;
`;
export const Button = styled.button.attrs((props) => ({
    className: (props.className ?? '') +
        (props.$loading ? ' loading' : '') +
        (props.$borderless ? ' borderless' : '')
})) `
  padding: 9px 12px 7px;

  position: relative;

  word-wrap: break-word;

  border: none;
  background: none;
  text-align: start;

  display: grid;

  ${({ $firstGridColumnWidth }) => $firstGridColumnWidth
    ? `grid-template-columns: ${$firstGridColumnWidth}px auto 1fr;`
    : 'grid-template-columns: auto auto 1fr;'}

  &:hover::before {
    content: '';

    display: block;

    position: absolute;
    inset: 0;

    opacity: 0.05;
    background: #457af5;
  }

  &.loading .icon,
  &.loading .name,
  &:hover .icon,
  &:hover .name {
    color: ${({ theme }) => theme.colors.blue1};
  }

  &:not(.borderless)::after {
    content: '';

    height: 1px;
    display: block;

    position: absolute;
    inset: auto 0 0 0;

    opacity: 0.1;
    background: #000;
  }
`;
export const Numberr = styled.span.attrs((props) => ({
    className: (props.className ?? '') + ' number'
})) `
  white-space: nowrap;

  justify-self: center;

  font-size: 14px;
  font-weight: 700;
  font-family: inherit;
  line-height: 15.4px;
  color: ${({ theme }) => theme.colors.gray4};
`;
export const Name = styled.span.attrs((props) => ({
    className: (props.className ?? '') + ' name'
})) `
  margin-left: 5px;

  font-size: 14px;
  font-weight: 600;
  line-height: 15.4px;
  color: ${({ theme }) => theme.colors.gray3};
`;
export const Icon = styled(ChapterIcon).attrs((props) => ({
    className: (props.className ?? '') + ' icon'
})) `
  color: ${(props) => props.theme.colors.gray500};
`;
