import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const Wrapper = styled.div `
  margin-bottom: 10px;

  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
`;
export const Filter = styled.button.attrs((props) => ({
    className: (props.className ?? '') + (props.$isActive ? ' active' : '')
})) `
  --base-color: #2f80ed;

  isolation: isolate;

  height: 26px;

  padding: 4px 9px 4px 10px;

  font-size: 14px;
  font-weight: 400;
  line-height: 110%;

  text-align: center;

  display: inline-flex;
  align-items: center;
  gap: 5px;

  white-space: nowrap;

  color: var(--base-color);

  background-color: transparent;

  position: relative;

  border: none;
  border-radius: 30px;

  &.active {
    color: ${({ theme }) => theme.colors.white};
  }

  &::after {
    content: '';

    display: block;

    position: absolute;
    inset: 0;

    z-index: 1;

    opacity: 0.2;

    border-radius: inherit;

    background-color: var(--base-color);
  }

  &.active::after {
    opacity: 1;

    z-index: -1;
  }

  &:nth-child(2) {
    margin-left: 7px;
  }

  &:nth-child(3) {
    margin-left: 6px;
  }

  &:nth-child(4) {
    margin-left: 10px;
  }
`;
export const PDFBookIcon = styled(CampusIcon).attrs({
    name: 'openBook',
    className: 'icon'
}) `
  display: block;
`;
export const HTMLBookIcon = styled(CampusIcon).attrs({
    name: 'paperWithHtmlTags',
    className: 'icon'
}) `
  display: block;
`;
export const TemplateIcon = styled(CampusIcon).attrs({
    name: 'paperWithCheck',
    className: 'icon'
}) `
  display: block;
`;
export const VideoIcon = styled(CampusIcon).attrs({
    name: 'youtubeLikeIcon',
    className: 'icon'
}) `
  display: block;
`;
