var LibraryObjectContentType;
(function (LibraryObjectContentType) {
    LibraryObjectContentType["EMBEDDED"] = "Embedded";
    LibraryObjectContentType["VIDEO"] = "Video";
    LibraryObjectContentType["TEMPLATE"] = "Template";
    LibraryObjectContentType["PDF"] = "PDF";
})(LibraryObjectContentType || (LibraryObjectContentType = {}));
export function contentTypeFromEnumValue(enumValue) {
    for (const [key, value] of Object.entries(LibraryObjectContentType)) {
        if (value === enumValue) {
            return LibraryObjectContentType[key];
        }
    }
    return undefined;
}
export default LibraryObjectContentType;
